@font-face {
  font-family: 'LexendDeca';
  src: url('../fonts/LexendDeca-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LexendDeca';
  src: url('../fonts/LexendDeca-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'LexendDeca';
  src: url('../fonts/LexendDeca-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'LexendDeca', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 24px;
}

.mw-300 { 
  max-width: 300px;
}
.mw-600 { 
  max-width: 600px;
}

.text-justify {
  text-align: justify;
}

.btn-danger {
  background-color: #d30000;
  border-color: #d30000;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 14px;
}
.btn-danger:hover {
  background-color: #d30000;
  border-color: #d30000;
}

.btn-success {
  background-color: #00bf63;
  border-color: #00bf63;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 14px;
}
.btn-success:hover {
  background-color: #00bf63;
  border-color: #00bf63;
}

.off-menu {
  font-size: 30px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5;
}
.off-menu ul {
  list-style: none;
  padding: 0;
}
.off-menu ul li a {
  text-decoration: none;
  color: #000000;
}

.main-page {
  background-color: #272727;
  background-image: url('../images/hqz-bg.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 655px;
  background-attachment: fixed;
}

.bottom-gradient {
  background-color: transparent;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.main-page h2 {
  font-size: 80px;
}

.main-page h3 {
  font-size: 50px;
}

.main-page video {
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 48px 34px 0px rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .main-page h2 {
    font-size: 50px;
  }
}
@media screen and (max-width: 766px) {
  .main-page h2 {
    font-size: 40px;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

footer {
  color: #888;
  font-size: 14px;
}
.footer-nav ul {
  list-style: none outside none;
}
.footer-nav ul li a {
  text-decoration: none;
  text-transform: uppercase;
  color: #888;
}